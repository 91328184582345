<template>
  <div
    class="container relative z-10 mx-auto w-full overflow-hidden py-32 text-white lg:mb-24 lg:mt-1 lg:h-screen lg:overflow-visible lg:px-3.5 lg:pb-44 lg:pt-32"
  >
    <Image
      img-class="w-full md:w-1/2 transform absolute -right-2/4 md:right-4 top-1/2 -z-10 -translate-y-1/2 lg:right-56 2xl:right-44"
      url="./main-vector.svg"
      loading="eager"
      :preload="true"
    />
    <div class="flex h-full w-full items-center justify-center">
      <GridLayout variant="fullBleed" class="pr-0 sm:pr-4">
        <div
          class="col-start-1 col-end-7 flex flex-col gap-10 lg:col-end-8 lg:self-center 2xl:gap-14"
        >
          <Headline
            class="pr-18 font-euclid text-white"
            level="h1"
            variant="xxlarge"
            :text="title"
          />
          <div class="flex gap-3 pr-4 sm:pr-0">
            <StrapiButton
              v-if="props.button"
              class="w-full justify-center sm:w-auto sm:justify-start"
              :variant="props.button?.variant"
              :text="props.button?.text"
              :should-authenticate="props.button?.shouldAuthenticate"
              :file="props.button?.file"
              :url="props.button?.url"
              :file-url="props.button?.fileUrl"
            />
            <StrapiButton
              v-if="videoButton"
              :text="videoButton.title"
              variant="secondary"
              class="cursor-pointer"
              @click="showVideo(videoButton)"
            />
          </div>
        </div>
        <div
          v-if="postsToShow?.length"
          class="lg:bg-black-600 col-start-1 col-end-7 mt-6 max-h-max lg:col-start-8 lg:col-end-13 lg:mt-0"
        >
          <BaseSlider
            :variant="postsToShow?.length > 1 ? 'pagination' : undefined"
            :options="sliderOptions"
          >
            <SplideSlide
              v-for="post in postsToShow"
              :key="post.id + post.title"
            >
              <component
                :is="lgAndSmaller ? 'a' : 'div'"
                v-if="post"
                :href="getPostPath({ ...post, type: 'Press Release' })"
                class="bg-black-600 mr-3 flex h-full flex-col gap-2 p-5 pb-14 text-white lg:mr-0 lg:gap-6 lg:px-10 lg:py-12 2xl:gap-8 2xl:px-12 2xl:py-16"
              >
                <Headline
                  class="font-semibold uppercase"
                  level="h6"
                  variant=""
                  text="Highlight"
                />
                <div class="flex flex-col gap-2">
                  <Headline
                    :text="formattedDate(post)"
                    variant=""
                    level="h6"
                    headline-class="font-mierb font-medium"
                  ></Headline>
                  <Headline
                    level="h5"
                    headline-class="line-clamp-1 lg:line-clamp-2 text-xxsmall custombp:text-xsmall font-mierb 2xl:!text-small font-semibold"
                    :text="post.title"
                  ></Headline>
                </div>
                <div
                  v-dompurify-html="
                    post.template[0]?.headline.headlineGroup.description
                  "
                  class="content-description text-bodyLarge 2xl:text-bodyXLarge line-clamp-1 font-medium lg:line-clamp-3"
                ></div>
                <StrapiButton
                  text="Read this article"
                  :url="getPostPath({ ...post, type: 'Press Release' })"
                  variant="ghost"
                  icon="arrow_forward"
                  underline
                  class="ml-auto mt-auto hidden cursor-pointer lg:block"
                  padding="p-0"
                />
              </component>
            </SplideSlide>
          </BaseSlider>
        </div>
      </GridLayout>
    </div>
    <BaseModal
      class="h-full w-full px-4 opacity-100 lg:px-0"
      content-class="h-full w-3/4"
      :esc-to-close="true"
      :model-value="showModal"
      @close-modal="showModal = false"
    >
      <Frames variant="vimeo" :url="videoUrl" :video-key="videoUrl" />
    </BaseModal>
  </div>
</template>
<script lang="ts" setup>
import dayjs from "dayjs";
import { useRoute as vUseRoute } from "vue-router";
import { computed as vComputed, PropType, ref as vRef } from "vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import BaseSlider from "../BaseSlider/BaseSlider.vue";
import { Button } from "~/components/StrapiButton/types";
import { Actions } from "~/types/gtag";
import { Video } from "~/components/VideoTestimonials/types";
import { trackEvent } from "~/utils/gtag";
import BaseModal from "~/components/Modal/BaseModal.vue";

const sliderOptions = {
  perPage: 1,
  autoplay: true,
  rewind: true,
  speed: 1000,
  classes: {
    pagination: "splide__pagination justify-start absolute-position",
  },
  breakpoints: {
    1000: {
      padding: {
        right: "20px",
      },
    },
  },
};

const route = vUseRoute();
const { find } = useStrapi4();

const showModal = vRef<boolean>(false);
const showDownloadPresentation = vRef<boolean>(false);
const videoUrl = vRef<string>("");

const breakpoints = useBreakpoints(breakpointsTailwind);
const lgAndSmaller = breakpoints.smallerOrEqual("lg"); // lg and smaller

const props = defineProps({
  title: { type: String, default: undefined },
  button: { type: Array as PropType<Button>, default: undefined },
  videoButton: { type: Array as PropType<Button>, default: undefined },
  pressReleaseLimit: { type: Number, default: 3 },
  articles: { type: Object as PropType<any>, default: () => {} },
});

const articleIds = props.articles?.data.map((article: unknown) => article.id);

const { data: fetchedPressReleases } = useAsyncData(
  `articles-${route.params.slug}`,
  () =>
    find("articles", {
      populate: "*",
      filters: {
        id: articleIds,
      },
      pagination: {
        limit: props.pressReleaseLimit,
      },
    })
);

const postsToShow = vComputed(() => {
  const posts = fetchedPressReleases.value?.data.map((item) => ({
    ...item.attributes,
  }));

  return posts?.sort((a, b) => {
    const dateA = new Date(a.publishedDate || a.publishedAt);
    const dateB = new Date(b.publishedDate || b.publishedAt);
    return dateB - dateA;
  });
});

function formattedDate(post) {
  const date = post.publishedDate ?? post.publishedDate ?? post.publishedAt;
  return dayjs(date).format("DD MMMM YYYY");
}
function showVideo(video: Video) {
  videoUrl.value = video.videoUrl;
  showModal.value = true;
  trackEvent({
    name: Actions.WATCH_VIDEO,
    campaignAttributes: false,
    video,
  });
}
</script>
